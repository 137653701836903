import '@/styles/course.css'
import '@/styles/styles.css'
import '@/app/globals.css'
import { CoursesPage } from '@/app/modules/course/pages'
import { ApiService } from '@/app/services/ApiService'
import { isBoom } from '@hapi/boom'
import { ROUTES } from '@/app/lib/constants'
import { CourseService } from '@/app/services/courses'
import { UserService } from '@/app/services/user'

const cookieName = process.env.NEXT_PUBLIC_AUTH_COOKIE_NAME

export async function getServerSideProps({ req, locale }) {
  try {
    ApiService.setAccessToken(req.cookies?.[cookieName] || '')
    ApiService.setHeader('accept-language', locale ?? 'en')

    const recommendations = await UserService.getRecommendations()
    const initialCoursesResponse = await CourseService.getCourses(
      {},
      { limit: 8 },
      { qualityScore: 'desc', random: 'asc' }
    )

    return {
      props: {
        recommendations,
        initialCoursesResponse: {
          pages: [initialCoursesResponse],
          pageParams: [1],
        },
        messages: (await import(`@/locales/${locale}/strings.json`)).default,
      },
    }
  } catch (e) {
    console.error(e)
    if (isBoom(e, 401)) {
      return {
        redirect: {
          destination: ROUTES.logout,
          permanent: false,
          locale,
        },
      }
    } else if (isBoom(e, 403)) {
      return {
        redirect: {
          destination: `/${locale}${ROUTES.accessDenied}`,
          permanent: false,
          locale,
        },
      }
    }

    throw e
  }
}

export default CoursesPage
